<template>
  <main>
    <content-page-header>
      <common-page-title title="お問い合わせ" />
    </content-page-header>
    <div class="content-body" v-if="state.isDisplayed">
      <panel-body>
        <p class="description">{{ state.description }}</p>

        <section class="inquiry-content border-t border-t--decoration">
          <form id="inquiryForm" class="inquiry-form">
            <template v-if="isEnterprise">
              <label class="form-function items-start">
                <div class="form-input-label">
                  お問い合わせ内容<sup class="type-required">*</sup
                  ><span class="form-input-label--explanation"
                    >ご利用目的やその他ご相談、お問い合わせ内容など、ご自由にご記入ください。</span
                  >
                </div>
                <div class="w-full">
                  <textarea
                    rows="4"
                    cols="40"
                    maxlength="300"
                    placeholder="お問い合わせ内容"
                    v-model="state.content"
                  ></textarea>
                </div>
              </label>
            </template>

            <template v-else>
              <label class="form-function">
                <div class="form-input-label">会社名</div>
                <div class="w-full">
                  <input
                    type="text"
                    placeholder="会社名"
                    v-model="state.companyName"
                  />
                </div> </label
              ><label class="form-function">
                <div class="form-input-label">
                  お名前<sup class="type-required">*</sup>
                </div>
                <div class="w-full">
                  <input type="email" placeholder="お名前" v-model="state.name" />
                </div> </label
              ><label class="form-function">
                <div class="form-input-label">
                  メールアドレス<sup class="type-required">*</sup>
                </div>
                <div class="w-full">
                  <input
                    type="email"
                    placeholder="メールアドレス"
                    v-model="state.email"
                    @input="state.error ? (state.error = false) : ''"
                  />
                </div>
              </label>

              <label class="form-function">
                <div class="form-input-label">
                  メールアドレス確認<sup class="type-required">*</sup>
                </div>
                <div class="w-full">
                  <input
                    type="email"
                    placeholder="メールアドレス確認"
                    v-model="state.emailConfig"
                    @input="state.error ? (state.error = false) : ''"
                  />
                  <div class="error-message" v-if="state.error">
                    <icon
                      name="error"
                      class="icon"
                    />メールアドレスの入力内容と、メールアドレス確認が一致しません
                  </div>
                </div>
              </label>
              <label class="form-function">
                <div class="form-input-label">
                  電話番号<sup class="type-required">*</sup>
                </div>
                <div class="w-full">
                  <input type="tel" placeholder="電話番号" v-model="state.tel" />
                </div>
              </label>
              <label class="form-function">
                <div class="form-input-label">
                  お問い合わせ分類<sup class="type-required">*</sup>
                </div>
                <div class="w-full">
                  <el-select
                    placeholder="お問い合わせ分類"
                    v-model="state.type"
                  >
                    <el-option
                      v-for="item in state.typeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.label"
                    >
                    </el-option>
                  </el-select>
                </div>
              </label>
              <label class="form-function items-start">
                <div class="form-input-label">
                  お問い合わせ内容<sup class="type-required">*</sup
                  ><span class="form-input-label--explanation"
                    >ご利用目的やその他ご相談、お問い合わせ内容など、ご自由にご記入ください。</span
                  >
                </div>
                <div class="w-full">
                  <textarea
                    rows="4"
                    cols="40"
                    maxlength="300"
                    placeholder="お問い合わせ内容"
                    v-model="state.content"
                  ></textarea>
                </div>
              </label>
            </template>
          </form>
        </section>

        <div
          class="error-message"
          v-for="(error, index) in state.errorMessage"
          :key="index"
        >
          <icon name="error" class="icon" />{{ error }}
        </div>
        <div class="submit-area">
          <template v-if="isEnterprise">
            <button
              class="submit-area-button submit-area-button--save"
              :disabled="state.content == ''"
              @click="enterpriseContact"
            >
              お問い合わせ
            </button>
          </template>

          <template v-else>
            <button
              class="submit-area-button submit-area-button--save"
              :disabled="
                state.email == '' ||
                state.emailConfig == '' ||
                state.type == '' ||
                state.content == ''
              "
              @click="contact"
            >
              お問い合わせ
            </button>

          </template>
        </div>
        <div id="hubspotForm" v-once>
        </div>
      </panel-body>
    </div>
  </main>
</template>

<script>
import { defineComponent, reactive, onMounted, watch, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import http from "@/utils/http";
import contentPageHeader from "@/components/contentPageHeader.vue";
import CommonPageTitle from "@/components/CommonTitle/CommonPageTitle.vue";
import PanelBody from "@/components/CommonPanelBody/PanelBody.vue";
import { ElSelect } from "element-plus";
import { ElOption } from "element-plus";
import axios from "axios";
import { ElLoading } from "element-plus";

export default defineComponent({
  name: "inquiryInput",

  components: {
    contentPageHeader,
    CommonPageTitle,
    PanelBody,
    ElSelect,
    ElOption,
  },
  props: [],
  setup() {
    const router = useRouter();
    const store = useStore();
    const isLogin = computed(() => store.state.user.isLogin);
    const isEnterprise = computed(() => store.state.user.isEnterprise)

    const state = reactive({
      companyName: "",
      name: "",
      email: "",
      emailConfig: "",
      tel: "",
      type: "",
      content: "",
      error: false,
      errorMessage: [],
      isDisplayed: false,
      description: "法人のお客様には別途特別なプランもご用意しております。お気軽にお問い合わせください。\n営業担当より3営業日以内にご連絡いたします。\n営業時間：10:00～17:00（土日祝を除く）",

      typeList: [
        {
          value: "1",
          label: "アカウント/コンテンツについて",
        },
        {
          value: "2",
          label: "お支払いについて",
        },
        {
          value: "3",
          label: "その他",
        },
        {
          value: "4",
          label: "協業の提案",
        },
        {
          value: "5",
          label: "退会について",
        },
      ],
    });

    window.scrollTo(0, 0);

    // method
    const contact = () => {
      state.errorMessage = [];
      if (state.email === state.emailConfig) {
        const body = {
          fields: [
            {
              name: "company",
              value: state.companyName,
            },
            {
              name: "full_name",
              value: state.name,
            },
            {
              name: "email",
              value: state.email,
            },
            {
              name: "phone",
              value: state.tel,
            },
            {
              name: "inquiry_category",
              value: state.type,
            },
            {
              name: "message",
              value: state.content,
            },
          ],
          context: {
            pageUri: "https://jissen.me/inquiry.php",
            pageName: "ジッセン!DX お問い合わせ",
          },
        };
        console.log(body);
        axios
          .post(
            "https://api.hsforms.com/submissions/v3/integration/submit/8942317/f6b3432a-9432-4de7-92d0-6ae52d046663",
            body
          )
          .then(() => {
            router.push("/inquiry/input/complete");
          })
          .catch((error) => {
            error.response.data.errors.forEach((el) => {
              state.errorMessage.push(
                el.errorType === "NUMBER_OUT_OF_RANGE"
                  ? "電話番号が無効です。"
                  : el.errorType === "INVALID_EMAIL"
                  ? "メールアドレスが無効です。"
                  : el.errorType === "INVALID_EMAIL"
                  ? "メールアドレスが無効です。"
                  : el.errorType === "REQUIRED_FIELD"
                  ? "必須項目を入力してください。"
                  : el.errorType === "INPUT_TOO_LARGE"
                  ? "入力可能文字数を超えています。"
                  : ""
              );
            });
          });
      } else {
        state.errorMessage.push("メールアドレスが一致しません。");
      }
    };
    const enterpriseContact = () => {
      state.errorMessage = [];
      http
        .post("/api/inquiries/send", {
          content: state.content
        })
        .then(() => {
          router.push("/inquiry/input/complete");
        })
        .catch((error) => {
          state.errorMessage.push(error.response.data.errors.content[0]);
        });
    };
    const fetchInquirySetting = () => {
      return http
        .get("/api/inquiries/setting")
        .then((response) => {
          state.description = response.data.description;
          return response;
        })
        .catch((error) => {
          return error.response;
        });
    };
    const reInit = async () => {
      console.log("re-init");
      init();
    };
    const init = async () => {
      if (isEnterprise.value) {
        await fetchInquirySetting();
      }

      state.isDisplayed = true; // 表示OK
    };

    onMounted(async () => {
      if (isLogin.value && store.state.user.servicePlanId === 0) {
        // ログイン済み、かつサーピスプラン未取得の場合、
        // 顧客情報を取得したタイミングで問い合わせ設定を取得する
        console.log("skip on-mount process");
        return;
      }

      init();
    });

    const loading = ElLoading.service({
      lock: true,
      text: "Loading...",
      background: "#fff",
    });

    watch(
      () => state.isDisplayed,
      () => {
        loading.close();
      }
    );
    watch(
      () => store.state.user.isEnterprise,
      async () => {
        await reInit();
      }
    );

    const script = document.createElement("script");
    script.src="https://js.hsforms.net/forms/embed/v2.js";
    document.body.appendChild(script);
    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "7710956",
          formId: "fa262e41-f6fa-4454-a1a6-63fe2dda1acd",
          target: "#hubspotForm"
        })
      }
    });

    return {
      store,
      state,
      isEnterprise,

      // method
      contact,
      enterpriseContact,
    };
  },
});
</script>

<style lang="scss" scoped>
::v-deep(.content-header) {
  height: auto;
  padding: 100px 100px 200px;
  background-size: cover;
  @include mq(sm) {
    padding: 100px 20px 200px;
  }
}
.description {
  line-height: 1.7;
  white-space: pre-wrap;
  word-wrap: break-word;
  display: none;
}
.content-body {
  position: relative;
  margin-top: -100px;
  margin-right: 50px;
  margin-left: 50px;
  margin-bottom: 50px;
  z-index: 1;

  @include mq(sm) {
    margin-right: 20px;
    margin-left: 20px;
  }
}
.inquiry-content {
  position: relative;
  margin-top: 65px;
  padding-top: 65px;
  display: none;
}

.inquiry-form {
  padding-bottom: 65px;
  border-bottom: 0.5px solid #0f0f11;
}
.inquiry-reason-list-item {
  &:nth-of-type(n + 2) {
    margin-top: 1em;
  }
}
.submit-area {
  display: none;
}
</style>
